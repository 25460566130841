import Vue from 'vue';
import axios from 'axios';
import store from '../auth/store';
import HttpError from './HttpError.vue'
import { EventBus } from './eventBus';



//var storage = JSON.parse(localStorage.getItem('msdckadmin'))
//if (storage && storage.token) 
    //axios.defaults.headers.common['Authorization'] = storage.token

axios.defaults.headers.common['Authorization'] = store.getters.token

axios.interceptors.request.use(
    (request) => {
        
        EventBus.$emit('start-progress');
        return request;
    }
);


/**
 * to do: implement refresh token
 */



axios.interceptors.response.use(
    (response) => {
        
        EventBus.$emit('finish-progress');
        return response
    },
    (error) => {
        
        EventBus.$emit('fail-progress');
        let text = error.response.data || error.message
        
        if(text.match(/ECONNREFUSED/i)) 
            text = `Some of our services are not currently available`
        
        Vue.$toast.error({
            component: HttpError,
            props: {
                html: text
            }
        });

        if(error.response.status == '401'){
            store.dispatch('logout')
            return Promise.reject(error.message);    
        }
        // Disabled this 31/10/23
        // Enabled in 16/9/24
        return Promise.reject(error);

    }
);

export default axios;