const View = () => import(/* webpackChunkName: "dashboard" */ '../../pages/Layout/View.vue');
import Redirect from 'src/pages/SMS/Payment/Stripe/Redirect';

export default {
    path: '/stripe',
    redirect: '/stripe/redirect',
    component: View,
    name: 'stripe',    
    children: [
      {
        path: 'redirect',
        name: 'redirect',
        components: { default: Redirect }
      }
    ]
}