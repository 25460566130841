<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">

    <vue-topprogress :height="6" ref="topProgress"></vue-topprogress>


    <notifications></notifications>
    
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot-scope="props" slot="links">
        <!--HOME-->
        <sidebar-item
          :link="{
            name: $t('sidebar.home'),
            //icon: 'tim-icons icon-components', //https://materialdesignicons.com/
            //icon: 'tim-icons',
            icon: 'view-dashboard-outline',
            path: '/home'
          }"
        >
        </sidebar-item>
        <!--/HOME-->
        <!--SMS-->
        <sidebar-item :link="{
           name: $t('sidebar.SMS'), icon: 'message-text-outline' }">
           <sidebar-item
            :link="{
              name: $t('sidebar.analytics'),
              icon: 'chart-box-outline',
              path: '/sms/analytics'
            }"
          >

          
          </sidebar-item>
          
          <sidebar-item
            :link="{
              name: $t('sidebar.messageSearch'),
              icon: 'chart-box-outline',
              path: '/sms/search'
            }"
          >
          </sidebar-item>

        </sidebar-item>      
        <!--/SMS-->
        
        <!--APIs-->
        <sidebar-item
          :link="{
            name: $t('sidebar.keys'),
            icon: 'transit-connection-variant',
            path: '/apis/keys'
          }"
        >
        </sidebar-item>
        <!--/APIs-->
        <!--DELIFY-->
        <sidebar-item 
          @clicked="delifyModel"
        :link="{ 
          name: $t('sidebar.delify'), 
          icon: 'send-check-outline', 
          path: delifyAllowed ? '/sms/delify' : '#'}">
          
          
          <span slot="badge" >
            <badge type="success" v-if="delifyAllowed">
              <span slot="badgeContent">BETA</span>
            </badge>
            <badge type="info" v-else>
              <span slot="badgeContent">CLICKME</span>
            </badge>
          </span>
        </sidebar-item>
        <!--/DELIFY-->

        <!--CONTACTS-->
        
        <sidebar-item 
        @click="linkClick"
        :link="{ 
            name: $t('sidebar.contacts'), 
            icon: 'contacts-outline', 
            path:  '/contacts'}" > 
            <span slot="badge">
            <badge type="success">
              <span slot="badgeContent">BETA</span>
            </badge>
          </span>
        </sidebar-item>
        
        <!--/CONTACTS-->
        <!--CAMPAIGNS-->
        <sidebar-item :link="{ 
            name: $t('sidebar.campaigns'), 
            icon: 'arrange-send-to-back' 
            }" > 
            <template slot="badge">
              <badge type="success">
                <span slot="badgeContent">BETA</span>
              </badge>
            </template>
            <!--
            <sidebar-item :link="{ name: $t('sidebar.newCampaign'), path: '/campaigns/new' }" > 
            </sidebar-item>
            -->
            <sidebar-item  :link="{ name: $t('sidebar.campaignsList'), path: '/campaigns/list' }" >
            </sidebar-item>
            <sidebar-item  :link="{ name: $t('sidebar.campaignStats'), path: '/campaigns/analytics' }" >
            </sidebar-item>
            <sidebar-item  :link="{ name: $t('sidebar.tenDlc'), path: '/campaigns/ten-dlc' }" >
            </sidebar-item>
            
            <!--
            <sidebar-item :link="{  name: $t('sidebar.campaignsList'),  path: '/campaigns/edit' }" >
            <span slot="badge">
              <badge type="info">
                <span slot="badgeContent">SOON</span>
              </badge>
            </span>
            </sidebar-item>
            <sidebar-item :link="{  name: $t('sidebar.landingPageEditor'),  path: '/campaigns/landing-page-editor' }" />
            -->            
            
        </sidebar-item>
        <!--/CAMPAIGNS-->
        
        
        
        
        
        <!--BILLING-->
        <sidebar-item :link="{ 
          name: $t('sidebar.payment'), 
          icon: 'wallet-outline', 
          path: '/payment' }" > 
            <sidebar-item :link="{ name: $t('sidebar.paymentPricing'), path: '/billing/pricing' }" >
            </sidebar-item>
            <sidebar-item :link="{ name: $t('sidebar.paymentUsage'), path: '/billing/usage' }" >
            </sidebar-item>
            <sidebar-item :link="{ name: $t('sidebar.paymetnHistory'), path: '/billing/history' }" >
            </sidebar-item>
            <sidebar-item :link="{ name: $t('sidebar.addFunds'), path: '/billing/funds' }" >
            </sidebar-item>
        </sidebar-item>
        <!--/BILLING-->
        

        <!--SETTINGS-->
         <sidebar-item
            :link="{
              name: $t('sidebar.settings'),
              icon: 'cog-sync-outline'
            }"
          >
          <!--
          <sidebar-item
            :link="{
              name: $t('sidebar.api-access-token'),
              path: '/settings/tokens'
            }"
          >
          </sidebar-item>
          -->
          <sidebar-item
            :link="{
              name: $t('sidebar.account'),
              path: '/settings/account'
            }"
          >
          </sidebar-item>
          
        </sidebar-item>
        <sidebar-item
            :link="{
              name: $t('sidebar.documentation'),
              path: '/documentation',
              icon: 'file-cog-outline'
            }"
          >
          </sidebar-item>
        <!--
        <sidebar-item
            :link="{
              name: $t('sidebar.users'),
              path: '/settings/users'
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.accountDetails'),
              path: '/settings/account-details'
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.account'),
              path: '/settings/user-profile'
            }"
          >
          </sidebar-item>

        <sidebar-item
            :link="{
              name: $t('sidebar.documentation'),
              path: '/documentation',
              icon: 'file-cog-outline'
            }"
          >
          </sidebar-item>
    
        <sidebar-item
            :link="{ name: $t('sidebar.userProfile'), path: '/pages/user' }"
          ></sidebar-item>
          
          <sidebar-item
            :link="{ name: $t('sidebar.timeline'), path: '/pages/timeline' }"
          ></sidebar-item>
          <sidebar-item
          :link="{
            name: $t('sidebar.widgets'),
            icon: 'tim-icons icon-settings',
            path: '/widgets'
          }"
        ></sidebar-item>
          
          <sidebar-item
            :link="{
              name: $t('sidebar.typography'),
              path: '/components/typography'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.buttons'), path: '/components/buttons' }"
          ></sidebar-item>
          
        <sidebar-item
            :link="{ name: $t('sidebar.panels'), path: '/components/panels' }"
          ></sidebar-item>
          
          <sidebar-item
            :link="{
              name: $t('sidebar.extendedForms'),
              path: '/forms/extended'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.wizard'), path: '/forms/wizard' }"
          ></sidebar-item> 
          -->
        <!--/SETTINGS-->
        
        <!--DOCS-->
        
        
        <!--/DOCS-->
        
          <!--
  
        <sidebar-item
            :link="{ name: $t('sidebar.wizard'), path: '/forms/wizard' }"
          ></sidebar-item> 
        <sidebar-item
            :link="{ name: $t('sidebar.wizard'), path: '/forms/wizard' }"
          ></sidebar-item>

         <sidebar-item
          :link="{
            name: $t('sidebar.analytics'),
            icon: 'tim-icons icon-chart-bar-32',
            path: '/analytics'
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dash'
          }"
        >
        </sidebar-item>
        <sidebar-item :link="{ name: $t('sidebar.contacts'), icon: 'fa fa-address-book', path: '/contacts' }" > </sidebar-item>
        
        
        <sidebar-item :link="{ name: $t('sidebar.pages'), icon: 'tim-icons icon-image-02' }">
          
          <sidebar-item
            :link="{ name: $t('sidebar.rtl'), path: '/pages/rtl' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.timeline'), path: '/pages/timeline' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.login'), path: '/login' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.register'), path: '/signup' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.lock'), path: '/lock' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.userProfile'), path: '/pages/user' }"
          ></sidebar-item>
        </sidebar-item>
  
        


        <sidebar-item :link="{ name: $t('sidebar.usertable'), icon: 'fa fa-address-book', path: '/components/usertable' }" > </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.forms'),
            icon: 'tim-icons icon-molecule-40'
          }"
        >
        <sidebar-item
            :link="{ name: $t('sidebar.regular'), path: '/forms/regular' }"
          ></sidebar-item> 
          <sidebar-item
            :link="{ name: $t('sidebar.extended'), path: '/forms/extended' }"
          ></sidebar-item> 
           <sidebar-item
            :link="{ name: $t('sidebar.validation'), path: '/forms/validation' }"
          ></sidebar-item> 
           <sidebar-item
            :link="{ name: $t('sidebar.wizard'), path: '/forms/wizard' }"
          ></sidebar-item> 
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.components'),
            icon: 'tim-icons icon-molecule-40'
          }"
        >
          <sidebar-item :link="{ name: $t('sidebar.multiLevelCollapse') }">
            <sidebar-item
              :link="{
                name: $t('sidebar.example'),
                isRoute: false,
                path: 'https://google.com',
                target: '_blank'
              }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item
            :link="{ name: $t('sidebar.buttons'), path: '/components/buttons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.gridSystem'),
              path: '/components/grid-system'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.panels'), path: '/components/panels' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.sweetAlert'),
              path: '/components/sweet-alert'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.notifications'),
              path: '/components/notifications'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.icons'), path: '/components/icons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.typography'),
              path: '/components/typography'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.widgets'),
              path: '/components/widgets'
            }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{ name: $t('sidebar.maps'), icon: 'tim-icons icon-pin' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.googleMaps'), path: '/components/maps/google' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.fullScreenMaps'),
              path: '/components/maps/full-screen'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.vectorMaps'), path: '/components/maps/vector-map' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.widgets'),
            icon: 'tim-icons icon-settings',
            path: '/components/widgets'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.charts'),
            icon: 'tim-icons icon-chart-bar-32',
            path: '/components/charts'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.calendar'),
            icon: 'tim-icons icon-time-alarm',
            path: '/components/calendar'
          }"
        ></sidebar-item>
-->

      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    
    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <router-view name="header"></router-view>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        
        <div class="d-flex justify-content-center">
          <div class="w-100" style="max-width:1700px">
            <base-alert type="info" dismissible icon="tim-icons icon-bell-55">
              Some features are in Beta (newly released). If you encounter any issues please contact support@message-deck.com
            </base-alert>
            <base-alert v-if="lowOnFunds" type="primary" dismissible icon="tim-icons icon-bell-55">
                  You are low on funds!
                  &nbsp;&nbsp;
                  <router-link 
                      class="btn btn-info btn-sm" 
                      to="/billing/funds" 
                      tag="button">  
                      Top Up
                  </router-link>
              </base-alert>
              <base-alert v-else-if="outOfFunds"  type="danger" dismissible icon="tim-icons icon-bell-55">
                  You are out of funds!
                  &nbsp;&nbsp;
                  <router-link 
                      class="btn btn-info btn-sm" 
                      to="/billing/funds" 
                      tag="button">  
                      Top Up
                  </router-link>
              </base-alert>
          </div>
        </div>
        

        <zoom-center-transition :duration="200" mode="out-in">
          <div class="d-flex justify-content-center">
            <!-- your content here -->
            <div class="w-100" style="max-width:1700px">
              <router-view></router-view>
            </div>
          </div>
        </zoom-center-transition>
      </div>
      <LiveAgent ref="liveAgent" />
      <!--
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      -->
      <content-footer v-if="$route.meta.showFooter"></content-footer>
    </div>

    <modal 
      style="padding:25px;"
      :show.sync="showDelifyModal" 
      size="large" 
      
      footerClasses="footer-padding">

      <h3 slot="header" class="title title-up">
          Lets get to know each other
      </h3>
      <p>
        Delify is a "Delivery verification" tool that was built in order to assist you with SMS delivery.
      </p>
      <p>
        To enable this feature in our dashboard, we'll need to gather some
        additional details about your business needs to ensure we provide the best possible experience and support
      </p>
      <template slot="footer">

        <base-button 
          native-type="submit" 
          type="primary"
          simple
          @click="toggle">
          Get in touch
        </base-button>
        
      </template>
    </modal>

  </div>
</template>
<script>
import { vueTopprogress } from 'vue-top-progress'


/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SidebarShare from './SidebarSharePlugin';
import {Badge,BaseAlert} from 'src/components'


function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import LiveAgent from './LiveAgent.vue';
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import { Modal } from 'src/components';

import Vue from 'vue';

import { EventBus } from '../../plugins/eventBus';

export default {
  components: {  
    BaseAlert,  
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare,
    Badge,
    LiveAgent,
    vueTopprogress,
    Modal
  },
  data() {
    return {
      showDelifyModal: false,
      lowOnFunds: false,
      outOfFunds: false,
      delifyAllowed: false,
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggle(){
      this.$refs['liveAgent'].toggle()
      this.showDelifyModal = !this.showDelifyModal
    },
    delifyModel(){
      if(!this.delifyAllowed) this.showDelifyModal = !this.showDelifyModal
    },
    linkClick(e){
      e.preventDefault()
      console.log('link click')
    },
    startProgress() {
      if(this.$refs.topProgress) this.$refs.topProgress.start();
    },
    finishProgress() {
      if(this.$refs.topProgress) this.$refs.topProgress.done();
    },
    failProgress() {
      if(this.$refs.topProgress) this.$refs.topProgress.fail();
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');
        
        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
    async account(){
      
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      const res = await this.$http.get(api_endpoint + '/gateway/accounts/read')
      if(res && res.status == 200) {
        const account = res.data
        await this.$store.dispatch('account', res.data)
        this.outOfFunds = account.client_account_balance <= ( -1 * account.client_allowed_balance )
        if(this.outOfFunds) return 
        this.lowOnFunds = account.client_account_balance <= account.client_low_balance_notification_limit
        this.delifyAllowed = account.client_account_delify
        
      }
      
      
      
      
    }
    
  },
  created(){
    this.account()
  },
  mounted() {
    
    this.initScrollbar();
    
    EventBus.$on('start-progress', this.startProgress);
    EventBus.$on('finish-progress', this.finishProgress);
    EventBus.$on('fail-progress', this.failProgress);
    EventBus.$on('show-agent', this.toggle);
    
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
