<template>
<div>
  


    
  <footer class="footer">
    <!--
    <div id="chat-wrraper">
     
     <basic-vue-chat 
      v-if="showChat"
      :title="'Live Agent'"
      :initial-feed="feed"
      @newOwnMessage="onNewOwnMessage"/>
      <br>
     <base-button 
     style="height: 6rem;width:6rem;border-radius: 50px;float:right"
     @click="showChat=!showChat" lg type="primary" round icon>
      <span id="chat-icons">
       <mdicon v-if="!showChat" style="display:block !important" name="face-agent" size="64" />   
       <mdicon v-if="showChat" style="display:block !important" name="close" size="44" />   
      </span>
     </base-button>
      
   </div>
   -->
    <div class="container-fluid">
    

      <ul class="nav">
        <!--
        <li class="nav-item">
          <a
            href="http://creative-tim.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Creative Tim
          </a>
        </li>
        
        
        <li class="nav-item">
          <a
            href="https://www.creative-tim.com/presentation"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            About Us
          </a>
        </li>
        <li class="nav-item">
          <a
            href="http://blog.creative-tim.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Blog
          </a>
        </li>
        -->
      </ul>
     
      <div class="copyright">
        &copy; {{ year }}, MESSAGE-DECK
        <!--
        <a href="https://binarcode.com" rel="noopener" target="_blank">BinarCode</a> and
        <a
          href="https://www.creative-tim.com/?ref=pdf-vuejs"
          target="_blank"
          rel="noopener"
          >Creative Tim</a
        >
        for a better web.
        -->

      </div>
      
    </div>

    


  </footer>
</div>
</template>
<script>

import BasicVueChat from 'basic-vue-chat'

export default {
  
  components: {
    BasicVueChat
  },
  methods: {
    onNewOwnMessage(data){
      //console.log(data)
    }
  },
  data() {
    return {
      showChat: false,
      //please send an email to support@message-deck.com or contact us via Skype https://join.skype.com/invite/EoeFWrJVYPzw
      feed: [
        {
          id: 'support',
          author: 'Agent',
          //imageUrl: 'http://path/to/image',
          contents: 'Hi there. Thank you for using message-deck SMS platform.',
          date: '16:30'
        },
        {
          id: 'support',
          author: 'Agent',
          //imageUrl: 'http://path/to/image',
          contents: 'Live chat is currently offline.',
          date: '16:30'
        },
        {
          id: 'support',
          author: 'Agent',
          //imageUrl: 'http://path/to/image',
          contents: 'For support please send an email to support@message-deck.com',
          date: '16:30'
        },
        {
          id: 'support',
          author: 'Agent',
          //imageUrl: 'http://path/to/image',
          contents: 'For urgent issues please contact us via Skype https://join.skype.com/invite/EoeFWrJVYPzw',
          date: '16:30'
        }
      ],
      year: new Date().getFullYear(),
      
    }
  }
};
</script>
<style>

.sidebar p {
  margin: 0 !important;
}

.sidebar .nav p {
  width: 85%;
  padding: 0 0 0 16px;
}

.sidebar .nav li > a {
  border-radius: 0px;
  padding: 10px 0px 8px 8px !important;
}

body{ 
  text-align: initial !important;
  font-family: "Poppins", sans-serif !important;
  background-color: #1e1e2f !important;
}

.input__field{
  pointer-events:none;
}

.messages-list{
  padding:5px !important;  
} 

.window__header__container {
  background: linear-gradient(90deg, #27293d, #B284ED);
  padding: 10px !important;
}
#window__messages__container{
  background: white !important;
}

.window__input__container{
  background: white !important;
}

#chat-wrraper{
  z-index:999;
  position: relative;
  right: 90px;
  bottom: 120px;
}

.basic-vue-chat {
  position: absolute;
    right: 0;
    bottom:inherit;
}

@media only screen and (max-width: 600px) {
  .basic-vue-chat .window{
    max-width: 375px !important;
  }
  #chat-wrraper{
      z-index:999;
      position: relative;
      right: 10px;
      bottom: 60px;
  }
}

#chat-icons > .btn span{

    display: block !important;
  
}
 
</style>
