<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
          
        </button>
      </div>
      <a class="navbar-brand" >
        {{ routeName }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ buisnesType && buisnesType!='null' ? `${buisnesType} account` : ''  }}       
      </a>
      
      
    </div>
    
    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!--
      <span class="navbar-brand" style="white-space:nowrap;padding-top: 11px;">
        <i style="position: relative;top:-2px;" class="tim-icons icon-money-coins"></i> BALANCE: 100050 EUR
      </span>
      <router-link  class="navbar-brand" style="white-space:nowrap;padding-top: 11px;" to="/forms/payment">
          <i style="position: relative;top:-2px;" class="tim-icons icon-simple-add"></i> ADD FUNDS
      </router-link>
      -->
    <!--      
      <div class="search-bar input-group" @click="searchModalVisible = true">
    
          <input type="text" class="form-control" placeholder="Search...">
          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>

        <button
          class="btn btn-link"
          id="search-button"
          data-toggle="modal"
          data-target="#searchModal"
        >
          <i class="tim-icons icon-zoom-split"></i>
        </button>
     
      </div>

      <modal
        :show.sync="searchModalVisible"
        class="modal-search"
        id="searchModal"
        :centered="false"
        :show-close="true"
      >
        <input
          slot="header"
          v-model="searchQuery"
          type="text"
          class="form-control"
          id="inlineFormInputGroup"
          placeholder="SEARCH"
        />
      </modal>
      https://materialdesignicons.com/
  -->
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"
      >
      
        <template slot="title">
          <mdicon name="currency-usd" size="28" />
        </template>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" style="color:5603ad">
            Current balance: {{accountBalance.toFixed(5)}} {{accountCurrency}}
          </a>
        </li>
        
      </base-dropdown>

      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"
      >
        <template slot="title">
          <div  v-if="notifications.length" class="notification d-none d-lg-block d-xl-block"></div>
          <mdicon name="bell-outline" size="28" />
          <p class="d-lg-none">New Notifications</p>
        </template>
        <li v-if="!notifications.length" class="nav-link">
          <a href="#" class="nav-item dropdown-item"
            >No notifications found</a
          >
        </li>
        <li 
          style="display: flex;align-items: center;color:black;white-space:nowrap; border-bottom:1px solid #5858580f;"
          v-for="notification in notifications" :key="notification.id">
            <div class="d-inline-block p-3">
              <i v-if="notification.notifications_schedule_template_category =='MAINTENANCE'" class="tim-icons icon-settings"></i>
              <i v-else-if="notification.notifications_schedule_template_category =='PRICING'" class="tim-icons icon-money-coins"></i>
              <i v-else-if="notification.notifications_schedule_template_category =='PRODUCT'" class="tim-icons icon-app"></i>
              <i v-else-if="notification.notifications_schedule_template_category =='BALANCE'" class="tim-icons icon-coins"></i>
            </div>
            <div class="d-inline-block pr-5 pl-1" style="font-size:16px">
              <div>{{ notification.notifications_schedule_subject }}</div>
              <div style="font-size:10px">
                {{ notification.notifications_history_created_at | fixDate }}
              </div>
            </div>
            
          
          
          

        </li>
        
      </base-dropdown>

      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title"
        >
          <!--
          <div class="photo"><img src="img/mike.jpg" /></div>
          <i class="tim-icons icon-satisfied"></i>
          -->
          <mdicon name="account-outline" size="28" />
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">Other</p>
        </template>
        <!--
        <li class="nav-link">
          <a href="/pages/user" class="nav-item dropdown-item">Profile</a>
        </li>
        -->
        
        <li class="nav-link">
          <router-link to="/settings/account">
            <a class="nav-item dropdown-item">Account</a>
          </router-link>
        </li>
        <!--
        <li class="nav-link">
          <router-link to="/settings">
            <a class="nav-item dropdown-item">Settings</a>
          </router-link>
        </li>
        
        -->
        <li class="nav-link">
          <span class="nav-item dropdown-item">
          Developer 
          &nbsp;
          &nbsp;
          <base-switch
            style="margin:0px;"
            id="mode-base-switch"
            v-model="user.user_is_developer"
            type="info"
            on-text="ON"
            off-text="OFF"
            @input="toggleDeveloper">
            </base-switch>  
      </span>
        </li>
        

        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a @click=logout() class="nav-item dropdown-item">Log out</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal,BaseSwitch } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import io from 'socket.io-client';
import store from '../../auth/store';

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal,
    BaseSwitch
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      accountBalance: 0,
      accountCurrency: null,
      accountAllowedBalance: null,
      notifications:[],
      user: {},
      buisnesType: null
    };
  },
  filters:{
    fixDate(timestamp){
      return new Intl.DateTimeFormat('en-GB', { 
        year: 'numeric', 
        month: 'long', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        timeZone: 'Europe/Paris' // Adjust to your EU timezone
      }).format(new Date(timestamp));
    }
  },
  mounted(){
    this.user = this.$store.getters.user
    
    if(this.$store.getters.account.client_account_type)
      this.buisnesType = this.$store.getters.account.client_account_type
    
    this.balance()
    this.notification()
  },
  methods: {
    async notification(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      const { status, data } = await this.$http.get(api_endpoint + '/notifications/scheduler/frontend')
      if(status == 200) {
        this.notifications = data
      }
    },
    async balance(){
      const account = await this.$store.getters.account
      if(!account) return
      this.accountBalance = parseFloat(account.client_account_balance)
      this.accountCurrency = account.client_account_currency
      this.accountAllowedBalance = account.client_allowed_balance
    },
    async toggleDeveloper(){
      //const api_endpoint = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT
      //console.log( this.user)
      //const res = await this.$http.post(api_endpoint + '/set-developer',  this.user)
      //if(res && res.status == 200){
      //  const done = await this.$store.dispatch('developer', this.user)  
      //}

      try{
        const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/users/update/one`,  this.user)
        if(res && res.status == 200) {
          await this.$store.dispatch('developer', res.data)
        }

        //this.$emit('socialTermsConfirmed', true);
      }catch(error){
        return this.$emit('socialTermsConfirmed', error.response.status);  
      }


    },
    logout () {
      this.$store.dispatch('logout')
      .then(() => {
      this.$router.push('/login')
      })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style >
.top-navbar {
  top: 0px;
}

#mode-base-switch .bootstrap-switch-handle-on {
    color: black;
}



</style>
