<template>
  <div>    
    <base-nav
      v-model="showMenu"
      type="white"
      :transparent="true"
      menu-classes="justify-content-end"
      class="auth-navbar fixed-top"
      id="my-top-navbar">
      <div slot="brand" class="navbar-wrapper">
        <img id="logo-mobile-image" style="max-height:50px;" :src="logo" alt="app-logo" />
      </div>

      <ul class="navbar-nav">
        <router-link  v-if="!isRegister" class="nav-item" tag="li" to="/signup">
          <a class="nav-link">
            <i class="tim-icons icon-laptop"></i> Signup
          </a>
        </router-link>
        
        
        <router-link v-if="!isLogin" class="nav-item" tag="li" to="/login">
          <a class="nav-link">
            <i class="tim-icons icon-single-02"></i> Login
          </a>
        </router-link>
        
      </ul>
    </base-nav>
    <div class="wrapper wrapper-full-page">
      <div class="full-page" :class="pageClass">
        <div class="content">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in">
            <router-view ></router-view>
            <slot></slot>
          </zoom-center-transition>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <nav>
              <ul class="nav">
                
                <li class="nav-item">
                  <a
                    href="https://message-deck.com/General-terms-and-conditions/"
                    target="_blank"
                    rel="noopener"
                    class="nav-link"
                  >
                    Terms And Conditions
                  </a>
                </li>
                
                <li class="nav-item">
                  <a
                    href="https://message-deck.com/"
                    target="_blank"
                    rel="noopener"
                    class="nav-link"
                  >
                    About Us
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="https://message-deck.com/privacy-policy/"
                    target="_blank"
                    rel="noopener"
                    class="nav-link"
                  >
                    Privacy Policy
                  </a>
                
                </li>
              </ul>
            </nav>
            <div class="copyright">
              &copy; {{ year }}, MESSAGE-DECK
              <!--
              <i class="tim-icons icon-heart-2"></i> by
              <a href="https://binarcode.com" target="_blank">BinarCode</a> and
              <a
                href="https://www.creative-tim.com/?ref=pdf-vuejs"
                target="_blank"
                rel="noopener"
                >Creative Tim</a
              >
              for a better web.
              -->
            </div>
          </div>
        </footer>
      </div>
    </div>
    
  </div>
</template>
<script>
import { BaseNav } from 'src/components';
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    BaseNav,
    ZoomCenterTransition
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      logo: '/img/CREATIVE/logo/register.png',
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    };
  },
  computed: {
    isLogin() {
      return this.$route.name == 'Login';
    },
    isRegister() {
      return this.$route.name == 'Register';
    },
    title() {
      let page = this.$route.meta.title || '404';
      return `${page} Page`;
      //return `${this.$route.meta.title} Page`;
    }
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  mounted() {
    this.setPageClass();
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
};
</script>
<style lang="scss">
//.navbar.auth-navbar {
  //top: 0;
//}


@media only screen and (max-width: 768px) {
  .navbar{
    top: 0px !important;
    padding: 10px 20px  10px 20px !important;
  }

  
  #logo-mobile-image {
    margin: 0px !important;
  }
}
.stages-wrapper label {
  color:#fafffe;
}


#my-top-navbar .container-fluid{
  padding:0 !important;
}

#my-top-navbar { 
  padding-left: 48px;
  padding-right: 48px;
  
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
