const View = () => import(/* webpackChunkName: "dashboard" */ '../../pages/Layout/View.vue');
const ApiAccessKeysManagement = () => import(/* webpackChunkName: "tables" */ 'src/pages/SMS/APIs/ApiKeysManagment.vue');

export default {
  path: '/apis',
  component: View,
  redirect: '/apis/keys',
  name: 'apis',
  children: [
    {
      path: 'keys',
      name: 'keys',
      components: { default: ApiAccessKeysManagement }
    }
  ]
}