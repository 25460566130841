
import lauth from './auth';
import sms from './sms';
import settings from './settings';
import apis from './apis';
import billing from './billing';
import campaings from './campaings';
import contacts from './contacts';
import documentation from './documentation';
import home from './home';
import components from './components';
import forms from './forms';
import labout from './about';
import lstripe from './stripe';

export const children = [
    sms,
    settings,
    apis,
    billing,
    campaings,
    contacts,
    documentation,
    home,
    components,
    forms
]
export const auth = lauth
export const about = labout
export const stripe = lstripe