import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import * as Cookies from 'js-cookie';

Vue.use(Vuex)

const local_auth_base_url = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT

export default new Vuex.Store({
  state: {
    account: {},
    notifications: {},
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    shortUrlDisclaimer: false
  },
  mutations: {
    shortUrlDisclaimer(state, confirmed){
      state.shortUrlDisclaimer = confirmed
    },
    account_status(state, account){
      state.account = account
    },
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, data){
      state.status = 'success'
      state.token = data.token
      state.user = data.user
      state.account = data.account
      axios.defaults.headers.common['Authorization'] = data.token
    },
    auth_token(state, token){
      state.token = token
    },
    auth_twofarequired(state, user, account, notifications){
      
      

      state.status = 'twofarequired'
      state.user = user
      state.account = account ? account : state.account
      state.notifications = notifications ? notifications : state.notifications
      
    },
    auth_error(state){
      state.status = 'error'
    },
    auth_logout(state){
      

      state.status = null
      state.token = null
      state.user = null
      state.account = null
      state.notifications = null
      state.shortUrlDisclaimer = false
      delete axios.defaults.headers.common['Authorization']
      
      
    },
    user_type(state, user){
      state.user = { ...state.user, ...user }
      
    },
    user_details(state, user){
      state.user = { ...state.user, ...user.userDetails }
      state.account = { ...state.account, ...user.userAccount }
    },
    confirm_terms(state, data){
      state.user = { ...state.user, ...data }
    }
  },
  actions: {
    shortUrlDisclaimer({commit}, confirmed){
      
      commit('shortUrlDisclaimer', confirmed)
    },
    account({commit}, account){
      commit('account_status', account)
    },
    loginFBGGL({commit}, data){
      commit('auth_request')
      return new Promise((resolve, reject) => {
        
        let { user, token, info, account } = data
        
        if(info && info.error){
          reject(info.message)
        }

        if(!token){
          commit('auth_error')
          reject('Authorization failed')
        }

        const {
          user_confirm_is_developer,
          user_confirm_terms,
          user_enable_2fa
        } = user

        const confirmedIsDeveloper = JSON.parse(user_confirm_is_developer)
        
        if(!user_confirm_terms){
          commit('auth_token', token)
          resolve(`signup`)
        }

        if(user_enable_2fa){
          user.jwtToken = token
          commit('auth_twofarequired', user)
          resolve('Confrim2FA')
        }

        
        commit('auth_success', {
          token: token,
          user: user,
          account: account
        })

        if(confirmedIsDeveloper)
          return resolve('home')

        resolve('/about')
        
      })
    },
    confirm({commit, state}, data){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        
        if(data.user_confirm_terms){
          commit('confirm_terms', data)
          
          resolve(true)
        }
        
        reject(false)
      })
    },
    retry2FA({state, commit}){
      return new Promise((resolve, reject) => {
        axios({
          url: local_auth_base_url + '/twoFA/resend', 
          data: state.user, 
          method: 'POST' 
        })
        .then(res => {
          if(!res.data){
            reject(false)
          }

          const { status2FA } = res.data
          
          resolve(status2FA)

        })
        .catch((err) => {            
          commit('auth_error')
          reject()
        })
        
      })
    },
    login2FA({state, commit}, code){
      return new Promise((resolve, reject) => {
        commit('auth_request')

        const userCode = {
          ...state.user,
          ...code
        }

        axios({
          url: local_auth_base_url + '/twoFA/confirm', 
          data: userCode, 
          method: 'POST' 
        })
        .then(res => {
          const { token } = res.data
          if(!token){
            commit('auth_error')
            reject()
          }
          
          

          //axios.defaults.headers.common['Authorization'] = token
          //commit('auth_token', token)
          commit('auth_success', {
            token: token,
            user: state.user,
            account: state.account
          })

          resolve(true)

        })
        .catch((err) => {            
          
          commit('auth_error')
          reject(err.message)
        })
          
      })
    },
    nextPage({state}){
      return new Promise((resolve, reject) => {

        if(!state.user) reject(false)
        const { user } = state
        
        const { user_confirm_is_developer, user_confirm_terms } = user
        const { token } = state
        const isSetDeveloper = user_confirm_is_developer == true || user_confirm_is_developer == "true"
        const isConfirmTerms = user_confirm_terms == true || user_confirm_terms == "true"

        

        if(!token){
          return resolve(`/login`)
        }

        if(!isConfirmTerms){
          return resolve(`/signup`)
        }

        if(!isSetDeveloper){
          return resolve(`/about/you`)
        }

        
        return resolve('/home')

    
      })
    },
    login({commit}, loginUser){
      
      return new Promise((resolve, reject) => {
        commit('auth_request')


        axios({
          url: local_auth_base_url + '/login', 
          data: loginUser, 
          method: 'POST' 
        })
        .then(resp => {
          if(!resp){
            commit('auth_error')
            reject()
          }

          let { user, token, status2FA, account } = resp.data

          
          if(!user){
            commit('auth_error')
            reject()
          }

          user = {
            ...user,
            ...{
              jwtToken: loginUser.jwtToken
            }
          }
          
          

          if(user.user_enable_2fa){
            commit('account_status', account)
            commit('auth_twofarequired', user)

            if(!status2FA){
              reject({
                user: user,
                reason: 'error2FA',
                message: ''
              })
              return
            }

            reject({
              user: user,
              reason: 'confirm2FA',
              message: ''
            })
            return
          }
          
          
          //axios.defaults.headers.common['Authorization'] = token

          commit('auth_success', {
            token: token,
            user: user,
            account: account
            
          })

          resolve(true)

        })
        .catch((err) => {            
          commit('auth_error')
          reject()
        })
          

      })
          
    },
    userDetails({commit, state}, user){
      return new Promise((resolve, reject) => {
        if(!user.userDetails) reject(false)
        if(!user.userAccount) reject(false)

          
        commit('user_details', user)
        

        resolve(true)
      })
    },
    companyDetails({commit, state}, companyDetails){
      return new Promise((resolve, reject) => {
        //if(!user.userDetails) reject(false)
        //if(!user.userAccount) reject(false)
        
          
        //commit('user_details', user)
        

        resolve(true)
      })
    },
    register({commit}, loginUser){
      
        return new Promise((resolve, reject) => {
          commit('auth_request')

          axios({
            url: local_auth_base_url + '/register', 
            data: loginUser, 
            method: 'POST' 
          })
          .then(res => {
            
            if(!res){
              commit('auth_error')
              reject()
            }
            
            let {user, status2FA, created, account, notifications } = res.data
            
            if(!user){
              commit('auth_error')
              reject()
            }

            user = {
              ...user,
              ...{
                jwtToken: loginUser.jwtToken
              }
            }

            if(created){
              commit('account_status', account)
              commit('auth_twofarequired', user, account, notifications)
              resolve(status2FA)  
              return
            }
            reject({
              user: user,
              reason: 'existing',
              message: ''
            })

            return
            
          })
          .catch(err => {
            commit('auth_error', err)
            localStorage.removeItem('token')
            reject(err)
          })
        })
    },
    logout({commit}){

      return new Promise((resolve, reject) => {
        //commit('RESET_STATE');
        commit('auth_logout')
        
        resolve(true)
      })

    },
    recover({commit}, recoverUser){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        
        axios({
          url: local_auth_base_url + '/recover', 
          data: recoverUser, 
          method: 'POST' 
        })
        .then(res => {
          if(!res){
            commit('auth_error')
            reject()
          }

          let {status2FA, user} = res.data

          if(!user){
            commit('auth_error')
            reject()
          }

          user = {
            ...user,
            ...{
              jwtToken: recoverUser.jwtToken
            }
          }
          commit('auth_twofarequired', user)
          resolve(status2FA)  
          
        })
        .catch((err) => {    
          
          commit('auth_error')
          reject(err)
        })

      })
    },
    reset({state, commit}, resetUser){
      return new Promise((resolve, reject) => {

        
        const submitUser = {
          ...state.user,
          ...resetUser
        }

        if(!submitUser.jwtToken){
          commit('auth_error')
          reject('Please re start your reset attempt')
          return
        }

        axios({
          url: local_auth_base_url + '/reset', 
          data: submitUser, 
          method: 'POST' 
        })
        .then(res => {
          //const user = res.data
          commit('auth_logout')
          resolve(true)  
          
        })
        .catch((err) => {    
          
          commit('auth_error')
          reject(err)
        })
      })
    },
    developer({state,commit}, user){
      return new Promise((resolve, reject) => {
        commit('user_type', user)
        resolve(true)
      })
    },
    switchUserMode({commit}, user){
      return new Promise((resolve, reject) => {
        commit('user_type',user)
        resolve()
      })
    }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    iseDeveloper: state => state.developer,
    account: state => state.account,
    token: state => state.token,
    shortUrlDisclaimer: state => state.shortUrlDisclaimer
  },
  plugins: [
    createPersistedState({
      key: 'msdckadmin', 
      storage: {
        getItem: (key) => Cookies.get(key),    // Retrieve the state from cookies
        setItem: (key, value) => {
          const expiresInMilliseconds = 12 * 60 * 60 * 1000; // Example: 10 seconds
          const expirationDate = new Date(new Date().getTime() + expiresInMilliseconds);
          Cookies.set(key, value, { 
            expires: expirationDate,
            secure: true, 
            sameSite: 'Strict',
            httpOnly: false
          });
        },

        removeItem: (key) => Cookies.remove(key), // Remove from cookies
      },
    }),
  ]
})

