import VueRouter from 'vue-router';
//import routes from './routes';
import { children, auth, about, stripe } from './routers';
import store from '../auth/store'
import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';


const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/home',
    name: 'Dashboard layout',
    children: children,
    meta: {
      requiresAuth: true
    }
  },
  auth,
  about,
  stripe,
  { path: '*', component: NotFound }    
];


//import { publicPath } from '../../vue.config'
let publicPath = process.env.VUE_APP_PUBLIC_PATH
console.log('>>>[INFO] serving path: ' + publicPath)
console.log('>>>[INFO] enviorment: ' + process.env.NODE_ENV)


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: "history",
  linkActiveClass: 'active',
  base: publicPath,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});


router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.requiresAuth)) {      
    const { isLoggedIn, account } = store.getters
    if ([isLoggedIn, account].every(value => value)) {
      const {client_status:isActiveAccount} = account
      if(isActiveAccount){
        if(to.name == 'delify' && !store.getters.account.client_account_delify){
          return next('/home')
        }
        next()
        return
      }
    }
    next('/login')
  } else {
    next()   
  }
})

export default router;
