import AboutYouLayout from 'src/pages/Pages/AboutYouLayout.vue';
const UserDetails = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register/OnBoard/UserDetails.vue');
const UserInfo = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register/UserInfo.vue');
const Organization = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register/Organization.vue');


export default {
    //path: '/about',
    path: '/about',
    component: AboutYouLayout,
    //name: 'Aboutuser',
    redirect: '/about/you',
    children: [
      {
        path: '/about/you',
        name: 'AboutUser',
        meta: {
          title: 'About you'
        },
        component: UserDetails
      },
      {
        path: '/about/action',
        name: 'UserAction',
        meta: {
          title: 'About you'
        },
        component: UserInfo
      },
      {
        path: '/about/organization',
        name: 'UserOrganization',
        meta: {
          title: 'Lets get to know each toehr'
        },
        component: Organization
      },
    ],
    meta: {
      requiresAuth: true
    }
}